@media (max-width: 576px) {
    .login-page__img {
        display: none;
    }

    .login-page__container {

        height: 100vh !important;
        width: 100%;
        

        .login-page__form-container {
            height: 100%;
            width: 100%;
            // background-color: rgba(223, 218, 206);
            background-color: rgba(237, 237, 237) !important;


            .login-page__form-input {
                display: flex;
                justify-content: center;
                align-items: center;

                input {
                    width: 20rem !important;
                }

                button {
                    width: 20rem !important;
                    height: 4rem !important;
                }
               
            }

            .login-page__form-text {
                // margin: 0;
                // padding: 0;
                // width: 0;


                h3 {
                    font-size: 4rem !important;
                }
            }

        }
    }

   .categories-container {
    width: 100% !important;
    height: 100vh !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;

    .popup__overlay {
        top: 0;
        bottom: 0;
        position: fixed !important;
    }

    .categories__menu {
        width: 100vw;

        .cart-btn {
            font-size: 1.2rem;
        }
    }


    .categories__img {
        display: none;
    }

    .categories__user-container {
        display: none !important;
        

    }

    .categories__menu {
        display: flex;
        padding: 2rem 2rem 2rem 2rem;
        background-color: rgba(237, 237, 237) !important;
        height: 115% !important;


        .product-button {
            width: 20rem !important;
            height: 3.5rem !important;
            font-size: 15px !important;
        }
    }

   }

   .overlay {

    .user-cart_container {
        padding: 4rem 2rem 4rem 2rem;
        width: 20rem;

        h2 {
            font-size: 30px;
        }

        li {
            font-size: 15px;
        }

        .user-cart_item-container {
            // display: flex;
            // justify-content: center;
            // align-items: center;
            // padding: 0;
            // margin: 0;

            .user-cart_item-button {
                margin-top: -0.2rem !important;

                .button-remove {
                    font-size: 12px;
                    width: 7rem;
                    text-align: left;
                    display: flex;
                    justify-content: center;
                    padding: 0;
                }
    
            }
    

        }

        .button-payment {
            width: 11rem;
            padding: 0.5rem;
            margin-top: 0;
        }

        .button-cancel {
            width: 11rem;
            padding: 0.5rem;
        }

       }

   }

   .user-confirmation__container {
    padding: 3rem 1rem 3rem 1rem;
    width: 20rem;
    margin: 0;
    padding: 0;

    h2 {
        font-size: 25px !important;
    }
    
    .user-confirmation__container-products {
        width: 15rem;

        p {
            font-size: 15px;
            width: 100%;
            text-align: left;
            margin: 0 0 0.5rem 0;

            i {
                display: none;
            }
        }
    }

    .popup__overlay {
        top: 0 !important;
        bottom: 0 !important;
    }

    h2 {
        font-size: 30px;
    }

    .user-confirmation__buttons-escape {
        width: 11rem !important;
        padding: 0.5rem !important;
    }

    .user-confirmation__buttons-ok {
        width: 11rem !important;
        padding: 0.5rem !important;
        margin-top: 0 !important;
    }
   }


}

@media (max-width: 767px) {
    /* Styles for smartphones in landscape mode */

    .user__show-purchases-list {
        font-size: 12px;
    
        .user_purchase-wrap {
            margin-right: 1rem;
        }
    }
    
    .purchase__wrap {
        height: 450px;
        width: 300px;
        margin-top: 1rem;
        overflow-y: scroll;
    
        h2 {
        letter-spacing: 0.1rem;
        padding: 1rem;
        }
    
    }

    .balance__purchases-wrap {
    
        p {
            font-size: 15px !important;
        }
     
         button {
             font-size: 15px !important;   
             padding-right: 0.8rem;
        
            }
     }

    .login-page__img {
        display: none;
    }

    .login-page__form-container {

        .login-page__form-text {

            h3 {
                font-size: 50px !important;
            }

            p {
                font-size: 20px !important;
            }

        }

        .login-page__form-input {
            input {
                height: 3rem !important;
                font-size: 15px !important;
            }

            button {
                height: 3rem !important;
                font-size: 20px !important;
            }
        }

    }

}