@use '../../base/colors' as *;
@use '../../base/typography' as *;


.balance-container {

    .balance__icon {
        font-size: 20px;
        color: $bg-color-solid;

        i {
            font-size: 7rem;
        }
    }

   .balance__balance {
    font-family: $primary-font;
    letter-spacing: 2px;
    font-size: 50px;
    margin: 1rem;
    color: $bg-color-solid;


    h3 {
    
        font-family: $secondary-font;
        letter-spacing: 0.5px;
        font-size: 25px;
        color: $bg-color-solid;
    }

   }
}