@use '../../base/colors' as *;
@use '../../base/typography' as *;
@use '../../components/misc/buttons' as *;

.logout-container {

    button {
        // padding: 0.2rem;
        width: 17rem;
        border-style: none;
        background-color: transparent;
        // border: 4px solid #EAE7E0;
        font-family: $primary-font;
        font-size: 25px;
        color: #a2a2a2;
        border-radius: 3rem;
        margin-top: 2.5rem;
        letter-spacing: 1px;
    }

    .navbar__logout {
        @include button-style-admin-logout;
    }
}