@use '../../base/colors' as *;
@use '../../base/typography' as *;
@use '../../components/misc/buttons' as *;
@use '../../utils/animations' as *;

.navbar__container {
    margin: 2rem 0 3rem 0;
    overflow: visible;
    // height: 100vh;

    nav {
        display: grid;
        grid-template-columns: 1fr 7fr 1fr;
        color: $font-color;

        &>div {
            list-style: none;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            width: 100%;

            &.admin__searchbar-container {
                flex-direction: column;

                input {
                    border-radius: 12px;
                    border-style: none;
                    background-color: rgb(241, 241, 241);
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    padding: 1.2rem;
                    min-width: 100%;
                    padding-left: 4rem;
                    font-size: 20px;
                    font-family: $secondary-font;
                }

                &>div {
                    width: 100%;
                }

                #search-icon {
                    position: absolute;
                    font-size: 2rem;
                    margin: 1rem 0.7rem 0.7rem 1rem;
                }
            }

            a {
                text-decoration: none;
                text-transform: uppercase;
                font-family: $secondary-font;
                letter-spacing: 0.1rem;
                font-size: 20px;
                color: $font-color;

            }

            button {
                border: none;
                background-color: transparent;
            }

            .fa-solid {
                font-size: 2.2rem;
            }
        }

        .navbar__toggle-menu {
            // background-color: #f4f4f4;
            background-color: $btn-color;
            margin-top: -32px;
            position: absolute;
            height: 100vh;
            z-index: 100;
            width: 30rem;
            display: grid;
            align-items: right;
            right: 0; 
            box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
            

            & > div {
                cursor: pointer;
                padding-bottom: 2rem;
                text-align: center;

                a {
                    color: white;
                    font-size: 25px;
                }
            }

            .navbar__toggle-menu_items {
                margin: 10rem 0 10rem 0;
            }

            .navbar__toggle-menu_close {
                padding: 3rem 1.5rem 3rem 1.5rem;
                margin-right: 2rem;
                margin-top: 0;
                position: absolute;
                justify-self: end; 
                align-self: start; 

                button {
                    font-size: 20px;
                    color: white;
                }
            }
        }
    }
}