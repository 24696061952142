@use '../../base/colors' as *;
@use '../../base/typography' as *;
@use '../../components/misc/buttons' as *;
@use '../../utils/animations' as *;

.error {
    // background-color: rgb(216, 145, 145);
    // padding: 2rem;
    // position: absolute;
    margin-top: 2rem;
    position: absolute;
    bottom: 9rem;
    width: 25rem;
    font-family: $secondary-font;
    // border: 1px solid red;
    text-align: center;
    color: rgb(185, 90, 90);
}

.login-page__container {
    background-color: $bg-color;
    display: flex;
    flex-direction: row;
    height: 100vh;
    // display: grid;
    // justify-content: center;

    // grid-template-columns: 1fr 2fr;
    // height: 100%;

    .login-page__img {
        background: url('../../../media/start-img.jpg') no-repeat center center;
        width: 30rem;
        background-size: cover;
        height: 100vh;
    }

    .login-page__form-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        height: 100vh;

        .login-page__form-text {
            text-align: center;
            padding-bottom: 2rem;

            h3 {
                font-family: $primary-font;
                font-size: 80px;
                letter-spacing: 0.2rem;
                color: $font-color;

                    -webkit-animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                            animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                
            }

            p {
                font-family: $secondary-font;
                font-size: 30px;
                color: $font-color;
                text-transform: uppercase;
            }
        }

        .login-page__form-input {
            display: flex;
            flex-direction: column;
            grid-template-rows: 1fr 1fr 1fr;
            justify-content: center;

            input {
                width: 400px;
                height: 70px;
                font-family: $secondary-font;
                font-size: 20px;
                border-style: none;
                margin: 0.5rem;
                padding: 1.2rem;

                &:focus {
                    border-color: $btn-color;
                    outline: none;
                    box-shadow: 0 0 0 0.17rem $btn-color;
                }

            }

            button {
                @include button-style;
                margin: 1.5rem 0 0 0.5rem;
            }
        }

    }
}