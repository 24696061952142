@use '../../base/colors' as *;
@use '../../base/typography' as *;
@use '../../components/misc/buttons' as *;
@use '../../utils/animations' as *;

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}


.categories-container {
    background-color: rgb(237, 237, 237);
    // display: flex;
    // flex-direction: row;
    // justify-content: center;
    // align-items: center;
    display: grid;
    grid-template-columns: 2fr 3fr;
    text-align: center;
    height: 100%;

	-webkit-animation: fade-in 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

            
    .categories__menu-welcome {
        padding: 2rem 0 0 0;
        font-family: $primary-font;
        font-size: 35px;
        letter-spacing: 2px;
        color: $font-color;


        p {
            padding: 1rem;
            font-family: $secondary-font;
            font-size: 25px;
            letter-spacing: 0;
            color: $font-color;
        }
    }

    .categories__user-container {
        display: flex;
        position: relative;
        overflow: hidden;
        // padding: 0 2rem 0 2rem;
        // background-color: yellow;

        .categories__img {
            background-image: url('../../../media/landing-page-img.jpg');
            background-size: cover;
            background-color: #DFDACE;
            // filter: blur(5px);
            height: 100vh;
            width: 100%;
            background-color: deeppink;


            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                // background-color: rgba($color: $btn-color, $alpha: 0.9);
                // backdrop-filter: blur(5px);
            }
        }

        .img-fika {
            display: none;
            background-image: url('../../../media/pineapple-landing.jpg');
        }

        .categories__user {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            z-index: 2;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }
    }


    .categories__menu {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        // width: 100vw;
       

        .categories__menu-welcome {

            .user_balance {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                p {
                    text-decoration: underline;
                }
            }

        }


    }

    .cart-btn {
        // background-color: deeppink;
        margin: 0 0 2rem 0;
        border: none;
        font-size: 2rem;
        // z-index: 100;
    }

    .cart-btn_personal {
        margin: 1.2rem 0 1.2rem 0;
        border: none;
        font-size: 2rem;
    }


    .user-change_pw {
        display: flex;
        flex-direction: column;
        justify-content: center;
        
        h2 {
            font-family: $primary-font;
            letter-spacing: 1px;
            font-size: 30px;
            margin: 1rem;
        }

        p{
            font-size: 20px;
            font-family: $secondary-font;
        }

        input {
            margin-top: 2rem;
            padding: 1rem 1rem 1rem 1.5rem;
            width: 25rem;
            border-radius: 2rem;
            border-style: none;
            font-size: 17px;
            background-color: rgb(255, 255, 255);
        }

        .pw-submit_button {
            margin-top: 1rem;
            padding: 1rem 1rem 1rem 1.5rem;
            width: 25rem;
            border-radius: 2rem;
            border-style: none;
            font-size: 17px;
            background-color: $btn-color;
            color: rgb(237, 236, 236);
        }
    }

}

.balance__purchases-wrap {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: row-reverse;

    button {
        background-color: transparent;
        border: none;
        text-decoration: underline;
        font-size: 20px;
        font-family: $secondary-font;
    }
}