@use '../../base/colors' as *;
@use '../../base/typography' as *;
@use '../../components/misc/buttons' as *;
@use '../../utils/animations' as *;
@use '../../layout/grid' as *;


.admin__container {
    margin-bottom: 5rem;

    .admin__products-info {
        justify-content: center;
        text-align: center;

        h1 {
            font-family: $primary-font;
            letter-spacing: 0.1rem;
            font-size: 45px;
            margin-top: 4.5rem;
        }

        p {
            font-family: $secondary-font;
            font-size: 25px;
        }

    }

    .admin__products-add {
        margin: 2rem 0 2rem 0;
        justify-content: center;
        text-align: center;

        button {
            padding: 1.5rem;
            width: 50rem;
            font-family: $secondary-font;
            text-transform: uppercase;
            font-size: 20px;
            border: none;
            background-color: #2C2C2C;
            color: white;

            i {
                margin: 0 1rem 0 0;
            }

        }

    }

    .admin__products-list {
        margin-top: 4rem;

        .admin__products-list_item {
            margin: 1rem 3rem 1rem 3rem;
            display: grid;
            text-align: start;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

            p {
                background-color: rgb(241, 241, 241);
                padding: 1.2rem;
                font-size: 20px;
                font-family: $secondary-font;
            }

            input {
                padding-left: 1rem;
                border: none;
                background-color: rgb(229, 229, 229);
                font-size: 18px;

            }

            input::placeholder {
                color: black;
            }

            // input {
            //     background-color: rgb(241, 241, 241);
            //     border: none;
            //     font-size: 20px;
            //     font-family: $secondary-font;
            //     padding-left: 1rem;
            // }

            .admin__products-list_item-wrap {
                display: grid;
                text-align: end;
                grid-template-columns: 1fr 1fr;

                button {
                    padding: 1rem;
                    border: none;
                    font-size: 20px;
                    text-decoration: underline;

                    i {
                        font-size: 20px;
                    }
                }
            }
        }
    }

}