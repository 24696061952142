@use '../../base/colors' as *;
@use '../../base/typography' as *;

@mixin button-style {
    font-family: $primary-font;
    color: $secondary-font-color;
    background-color: $btn-color;

    letter-spacing: 0.1rem;
    font-size: 25px;
    border-radius: 38px;
    border-style: none;
    
    width: 400px;
    height: 70px;

  }

  @mixin button-style-confirm {
    padding: 1rem;
    width: 14rem;
    margin: 0 0.5rem 1rem 0.5rem;
    border: 2.5px solid $btn-color;
    border-radius: 10rem;
    font-family: $secondary-font;
    text-transform: uppercase;
    font-size: 15px;
    background-color: $btn-color;
    color: white;
  }

  @mixin button-style-admin-logout {
    padding: 1rem;
    width: 14rem;
    margin: 2rem;
    border: 2.5px solid white;
    border-radius: 10rem;
    font-family: $secondary-font;
    text-transform: uppercase;
    font-size: 20px;
    background-color: $btn-color;
    color: white;
  }