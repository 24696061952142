@use '../../base/colors' as *;
@use '../../base/typography' as *;
@use '../../components/misc/buttons' as *;
@use '../../utils/animations' as *;
@use '../../layout/grid' as *;



.single-user__container {

    .single-user__info {
        margin: 3rem;
        text-align: center;
        letter-spacing: 1px;


        h2 {
            font-family: $primary-font;
            font-size: 40px;
            margin-bottom: 0.5rem;
        }

        h3 {
            font-family: $secondary-font;
            text-transform: uppercase;
            color: $btn-color;
        }
    }

    .single-user__balance-purcased_wrap {
        // padding: 0 5rem 0 5rem;
        display: flex;
        justify-content: center;
        grid-template-columns: 1fr 2fr;
        // align-items: center;

    }

    .single-user__balance {
        background-color: $btn-color;
        padding: 3rem;
        width: 22rem;

        display: grid;
        justify-content: center;
        align-items: center;

        .popup__balance-container {
            display: flex;
            justify-content: center;
            align-items: center;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        .popup__balance {
            background-color: $popup-color;
            width: 35rem;
            position: relative;


            .popup__balance-info {
                padding: 5rem;
                text-align: center;
            }

            .popup__close {
                position: absolute;
                top: 0;
                right: 0;
                margin-top: 2rem;
                font-size: 20px;
            }

            h2 {
                font-family: $primary-font;
                letter-spacing: 1px;
                font-size: 30px;
                margin: 1rem;
            }

            h3 {
                font-family: $secondary-font;
                color: black;
                margin-bottom: 1rem;
            }

            input {
                padding: 1rem 1rem 1rem 1.5rem;
                width: 15rem;
                margin: 1rem;
                border-radius: 2rem;
                border-style: none;
                font-size: 17px;
                text-align: center;
            }

            button {
                background-color: transparent;
                width: 10rem;
                padding: 1rem;
                border: none;
                color: $btn-color;
                font-size: 17px;
                font-weight: 600;
                text-decoration: underline;


            }
        }

        .balance__balance {
            text-align: center;
            color: white;

            .password__reset-btn {
                border-radius: none;
                border: none;
                text-decoration: underline;
            }

            i {
                color: white;
                font-size: 70px;
            }

            button {
                background-color: transparent;
                border: none;
                padding: 1rem;
                margin-top: 2rem;
                font-family: $secondary-font;
                font-size: 15px;
                border-radius: 2rem;
                border: 2px solid white;
                color: white;
                width: 10rem;
            }
        }

        h2 {
            font-size: 50px;
        }

        h3 {
            color: white;
            font-size: 20px;
        }
    }

    .single-user__purchased {
        padding: 2rem 0 2rem 4rem;
        overflow: auto;
        height: 30rem;

        h4 {
            font-family: $secondary-font;
            text-transform: uppercase;
            margin-bottom: 2rem;
            color: $btn-color;
        }

        .admin__show-users_list {
            display: flex;
            padding-bottom: 1rem;
            font-family: $secondary-font;
            font-size: 17px;

            p {
                margin-right: 4rem;
            }

            i {
                margin-left: auto;
            }
        }
    }



    .popup__undo-purchase_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        .popup__undo-purchase {
            background-color: $popup-color;
            width: 35rem;
            position: relative;
            padding: 5rem;
            text-align: center;


            .pop__undo-purchase_header {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                h3 {
                    color: black;
                    font-family: $primary-font;
                    font-size: 1.5rem;
                }

                p {
                    font-family: $secondary-font;
                }
            }

            .popup__undo-purchase_body{
                display: flex;
                flex-direction: column;

                .popup__date_time {
                    display: flex;
                    justify-content: space-evenly;
                    width: 100%;
                    margin: 1rem;
                    align-self: auto;

                    .popup__purchase-date,
                    .popup__purchase-time {
                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                        font-weight: 600;
                        font-size: 1.1rem;

                        i {
                            margin-right: 10px;
                        }
                        p {
                            font-family: $secondary-font;
                        }
                    }
                }

                .popup__purchase-items {
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                    margin: 7px;
                    font-size: 1.2rem;

                    p {
                        font-family: $secondary-font;
                    }
                }

                b {
                    font-family: $primary-font;
                    font-size: 1.4rem;
                    font-weight: 200;
                }
            }
        }

        .popup__undo-purchase__btn-div {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-evenly;
            
            button {
                background-color: transparent;
                border: none;
                padding: 1rem;
                margin-top: 2rem;
                font-family: $secondary-font;
                font-size: 15px;
                border-radius: 2rem;
                border: 2px solid $btn-color;
                width: 10rem;
            }
        }
    }
}
