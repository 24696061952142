@use '../../base/colors' as *;
@use '../../base/typography' as *;
@use '../../components/misc/buttons' as *;
@use '../../utils/animations' as *;
@use '../../layout/grid' as *;

.admin__info-text {
    padding-bottom: 2rem;
}
.admin__user-btns {
    
    button {
        padding: 1rem;
        margin: 0.2rem;
        width: 200px;
    }
}

.admin__show-users {
    margin: 3rem;

    .admin__show-users_list {
        background-color: deeppink;
        list-style: none;
        margin: 1rem 3rem 1rem 3rem;
        // padding: 2rem;
        color: white;
        display: grid;
        text-align: start;
        display: grid;
        grid-template-columns: 2fr 1fr 1fr;

        .admin__show-users_list-balance {
            background-color: bisque;
            display: grid;
            text-align: end;
            
            button {
                padding: 1rem;
            }
        }

    }
}